import React from 'react';
import contentStyles from './content.module.css';
import DownloadButton from '../downloadButton';

const Content2 = () => {
  return (
      <section className={contentStyles.main}>
        <div className={`container ${contentStyles.container}`}>
          <div className="container__inside">
            <div className={contentStyles.textContainer} data-sal="fade-in"
                 data-sal-delay="300"
                 data-sal-easing="ease">
              <div className={contentStyles.textContainerInside}>

                <h2>We have them all.<br/> <b>For every application
                  the perfect solution.</b></h2>
                <p>We offer you an incomparable range of hoses,<br className="hide-mobile" /> hose lines, connectors and accessories.</p>
              </div>
            </div>
            <a href="https://oxomi.com/p/3000912" target="_blank">
            <img className={contentStyles.image} src={'/images/content2-en.svg'}
                 alt=""
                 data-sal="fade-in"
                 data-sal-delay="200"
                 data-sal-easing="ease"/>
            </a>
          </div>
        </div>

        <DownloadButton className={contentStyles.downloadButton} type="pdf" href="/pdfs/Schauenburg Hose Technology Group - Product Application Index.pdf" lang="en"/>
        <DownloadButton className={contentStyles.downloadButton2} type="xls" href="/pdfs/Schauenburg Hose Technology Group - Product Application Index.xltx" lang="en" />


      </section>
  );
};

export default Content2;