import React from 'react';
import contentStyles from './content.module.css';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

const Content5 = () => {
  const data = useStaticQuery(graphql`
      query {
          image: file(relativePath: {eq: "content5.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 785) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);

  return (
      <section className={contentStyles.main}>

        <div className={contentStyles.textContainer}
             data-sal="fade-in"
             data-sal-delay="0"
             data-sal-easing="ease">
          <div className={contentStyles.textContainerInside}>
            <h2><b>WE ARE HERE FOR YOU.<br/>
              ANYTIME. ALL ACROSS EUROPE.</b><br/>
              CONTACT US. WE ARE GLAD TO ASSIST YOU.</h2>

            <h3>Germany</h3>
            <p><a href="https://www.apd-schlauchtechnik.de/en/" target="_blank" rel="noreferrer">APD Schlauchtechnik GmbH</a><br/>
              Viersen</p>
            <p><a href="https://www.schauenburg-rk.com/site/en" target="_blank" rel="noreferrer">Schauenburg Ruhrkunststoff GmbH</a><br/>
              Mülheim / Ruhr</p>

            <p><a href="https://www.vacuflex.de/en/" target="_blank" rel="noreferrer">VACUFLEX GmbH</a><br/>
              Mörfelden</p>
            <h3>France</h3>
            <p><a href="https://www.flexadux.fr" target="_blank" rel="noreferrer">Flexadux International S.à.r.l</a><br/>
              Montélimar</p>

            <p><a href="https://www.gap-plastomere.com/en/" target="_blank" rel="noreferrer">GAP Plastomère S.à.r.l</a><br/>
              Montélimar</p>

            <h3>Italy</h3>
            <p><a href="https://www.tubione.it" target="_blank" rel="noreferrer">TubiOne S.r.l</a><br/>
              Varese</p>

            <h3>Romania</h3>
            <p><a href="https://inovaflex.ro/en/" target="_blank" rel="noreferrer">APR Inovaflex S.r.l</a><br/>
              Calarasi</p>
          </div>
        </div>


        <a href="mailto:hello@schauenburg-hose.com"
           className={contentStyles.contactIcon}
           data-sal="slide-up"
           data-sal-delay="300"
           data-sal-easing="ease">
          <img src={'/images/contact-icon-en.svg'}
               alt="Contact"/>
        </a>

        <div className={contentStyles.imageWrap}>

          <a href="https://www.apd-schlauchtechnik.de/en/" target="_blank" rel="noreferrer" className={contentStyles.imageLink1}>www.apd-schlauchtechnik.de</a>
          <a href="https://www.schauenburg-rk.com/site/en" target="_blank" rel="noreferrer" className={contentStyles.imageLink2}>www.schauenburg-rk.com</a>
          <a href="https://www.vacuflex.de/en/" target="_blank" rel="noreferrer" className={contentStyles.imageLink3}>www.vacuflex.de</a>
          <a href="https://www.gap-plastomere.com/en/" target="_blank" rel="noreferrer" className={contentStyles.imageLink4}>www.gap-plastomere.com</a>
          <a href="https://www.flexadux.fr" target="_blank" rel="noreferrer" className={contentStyles.imageLink7}>www.flexadux.fr</a>
          <a href="https://inovaflex.ro/en/" target="_blank" rel="noreferrer" className={contentStyles.imageLink5}>www.inovaflex.ro</a>
          <a href="https://www.tubione.it" target="_blank" rel="noreferrer" className={contentStyles.imageLink6}>www.tubione.it</a>

          <Img className={contentStyles.image}
               fluid={data.image.childImageSharp.fluid}/>
        </div>

      </section>
  );
};

export default Content5;