import React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';

import Header from './header.en';
import Footer from './footer.en';
import './layout.css';

const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQueryEn {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);

  return (
      <>

        <div className="layout-main">
          <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
          <main>{children}</main>

          <Footer/>
        </div>
      </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
