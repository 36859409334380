import React from 'react';
import footerStyles from './footer.module.css';

class Footer extends React.Component {
  render() {
    return (
        <footer className={footerStyles.main}>

          <img className={footerStyles.logo} src={'/images/logo-footer.svg'} alt="SCHAUENBURG HOSE TECHNOLOGY GROUP"/>

          <div className={footerStyles.section1}>
            <div className={footerStyles.columnMobile}>
              Weseler Str. 35 | 45478 Mülheim-Ruhr (Germany)
            </div>
            <div className={footerStyles.columnMobile}>
              <div className={footerStyles.hideMobile}> | </div>
              T +49 208 99 91 0 | F +49 208 99 91 231
            </div>
            <div className={footerStyles.columnMobile}>
              <div className={footerStyles.hideMobile}> | </div>
              Mail: <a href="mailto:hello@schauenburg-hose.com">hello@schauenburg-hose.com</a>
            </div>
          </div>

          <div className={footerStyles.section2}>
            <div className={footerStyles.columnMobile}>© 2020<div className={footerStyles.hideMobile}> | </div></div>
            <div className={footerStyles.columnMobile}>Schauenburg Hose Technology Group<div className={footerStyles.hideMobile}> | </div></div>
            <div className={footerStyles.columnMobile}>
              <ul>
                <li><a href="https://schauenburg.com/en/imprint" target="_blank" rel="noreferrer">Imprint</a></li> <li>|</li> <li><a href="https://schauenburg.com/en/privacy" target="_blank" rel="noreferrer">Privacy Policy</a></li>
              </ul>
            </div>
          </div>


        </footer>
    );
  }
}

export default Footer;