import React from 'react';
import contentStyles from './content.module.css';

const Content3 = () => {
  return (
      <section className={contentStyles.main}>
        <div className={`container ${contentStyles.container}`}>
          <div className="container__inside"
               data-sal="fade-in"
               data-sal-delay="0"
               data-sal-easing="ease">
            <h2>sustainability is<br/>
              <b>our responsibility.</b></h2>

            <div className={contentStyles.columns}>
              <div className={contentStyles.columnText}>
                <p>Sustainability plays a major role in our family business.
                  The responsible handling and efficient processing of raw materials in our production processes, the reduction of waste and the use of renewable energies are just a few examples of our commitment to sustainability. We are the first German hose manufacturer to create a new product range that combines ecological and economic advantages - along the entire value chain. Even the raw materials used are produced at our site in an environmentally friendly manner to avoid unnecessarily long supply chains and the associated CO2 emissions.</p>
                  <p>As one of the world‘s leading hose manufacturers, we are constantly looking for sustainable and innovative solutions and their implementation. With our sustainable technologies, our environmentally friendly production processes and our ECO products, we make a positive contribution to our climate and our environment and thus also to our customers and employees.</p>
              </div>

              <div className={contentStyles.columnLogos}>
                <img src={'/images/eco-label.svg'} alt="ECO Friendly"/>
                <img src={'/images/made-in-germany-label.png'} alt="Made in Germany"/>

              </div>
            </div>

          </div>
        </div>

      </section>
  );
};

export default Content3;