import React from "react"

import Layout from "../components/layout.en"
import SEO from "../components/seo"
import Content1 from '../components/content1/content1.en';
import Content2 from '../components/content2/content2.en';
import Content3 from '../components/content3/content3.en';
import Content4 from '../components/content4/content4.en';
import Content5 from '../components/content5/content5.en';
import Content6 from '../components/content6/content6.en';
import Content7 from '../components/content7/content7.en';

const IndexPage = () => (
  <Layout>
    <SEO title="Schauenburg Hoses" />

    <Content1/>
    <Content2/>
    <Content3/>
    <Content4/>
    <Content5/>
    <Content6/>
    <Content7/>

  </Layout>
)

export default IndexPage
