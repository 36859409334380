import React from 'react';
import Img from 'gatsby-image';
import {graphql, useStaticQuery} from 'gatsby';
import contentStyles from './content.module.css';

const Content1 = () => {
  const data = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: {eq: "content1.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);

  return (
      <section className={contentStyles.main}>
        <Img className={contentStyles.image}
             fluid={data.placeholderImage.childImageSharp.fluid}/>

        <div className={`container ${contentStyles.container}`}>
          <div className="container__inside" data-sal="fade-in"
               data-sal-delay="300"
               data-sal-easing="ease">
            <h1>We <b>create</b><br/> the best <b>connections.</b></h1>
            <p>We are one of the largest plastic hose manufacturers in the world and are known for our intelligent and flexible solutions. In addition to a wide range of high-quality standard products and services, we offer you tailor-made solutions that provide perfect support in meeting your specific challenges.</p>
          </div>
        </div>
      </section>
  );
};

export default Content1;